import React from 'react';

import { Cookies} from "react-cookie-consent";
import { Container, Row, Col } from 'react-bootstrap';



const CookiePreferences = () => {
  
  const handleResetCookie = () => {
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
    Cookies.remove("CookieConsent");
    
  };


  return (

    <Container fluid className="content-block container-fluid cookie-preferences">
      <Row className="align-items-end header-row">
        <Col data-aos={"fade-up"} xs={12} md={12} lg={12} className="header-wrapper">
          <h3>
          Reset Cookie Preferences
          </h3>
              <button className="button" onClick={() => handleResetCookie()}>Reset Cookies</button>
        </Col>
      </Row>
    </Container>
  );
};

export default CookiePreferences;
