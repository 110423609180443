import React, { useEffect} from "react";
import Blocks from "../components/Blocks";
import Footer from '../components/Footer';
import SEO from "../utils/SEO";
import Header from "../components/Header";
import CareerBlock from '../components/CareerBlock';
import { useLocation } from 'react-router-dom';
import CookiePreferences from "../components/PageBlocks/ContentBlocks/CookiePreferences";


function Page(props) {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <div className={"page " + props.page.slug}>
        <SEO title={props.page.pageTitleSEO} description={props.page.pageDescriptionSEO} indexStatus={props.page.hideFromSiteMap} />
        <Header />

          <Blocks pageData={props.page.pageBlocks} maps={props.maps}/>
          {props.page.slug ==="careers" ? <CareerBlock data={props.careers}/>:''}
          {props.page.slug ==="privacy-policy" ? <CookiePreferences/>:''}

      </div>
      <Footer footerData={props.footerData} />
    </>
  );
}

export default Page
