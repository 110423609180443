import { Row, Col, Container, Dropdown } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import Helpers from "../utils/Helpers";
import axios from 'axios';

const CareerBlock = ({ data }) => {
    const [CareerState, setCareerState] = useState(data);
    const LocationState =
    [
        { title: "Niles, Illinois" },
        { title: "Hybrid - Niles" },
        { title: "Remote - US" },
        { title: "Bolingbrook, Illinois" },
        { title: "Elgin, Illinois" },
        { title: "Berwyn, Illinois" },
        { title: "Cicero, Illinois" }
    ];
    const TeamState =
    [
        { title: "Accounting / Finance" },
        { title: "Administration" },
        { title: "Agency Operations" },
        { title: "Claims" },
        { title: "Compliance / Legal" },
        { title: "Human Resources" },
        { title: "IT" },
        { title: "Marketing" },
        { title: "Operations" },
        { title: "Product Management" },
        { title: "Risk Management / Actuarial" },
        { title: "Underwriting" }
        
    ];
    const [TotalJobCount, setTotalJobCount] = useState(data.length);


    const [SelectLocationState, setSelectLocationState] = useState('All');
    const [SelectTeamState, setSelectTeamState] = useState('All');


    useEffect(() => {
        function fetchLeverData(team, location) {
            var parameters = {
                ...(SelectTeamState !== "All" && {"filters[department][$eq]" : team}),
                ...(SelectLocationState !== "All" && {"filters[location][$eq]" : location})
            }
            
            var url = process.env.REACT_APP_STRAPI_API_URL+"/api/careers";
            axios.get(url, {
                params: parameters
              }).then((response) => {
                setCareerState(response.data.data);
                setTotalJobCount(response.data.data.length)
            });
    
    
        }
        fetchLeverData(SelectTeamState, SelectLocationState)

    }, [SelectTeamState, SelectLocationState]);



    function handleLocationSorterSelect(event) {
        setSelectLocationState(event);
    }

    function handleTeamSorterSelect(event) {
        setSelectTeamState(event);
    }

    

    return (
        <Container fluid className="career-block" id="jobs">
            <div className="career-wrapper">
                <Row className="header-row" data-aos="fade-up" data-aos-delay="200">
                    <Col xs={12}>
                        <h1>Open Roles</h1>
                        <p>Find your dream job – explore our open positions.</p>
                    </Col>
                </Row>
                <Row data-aos="fade-up" data-aos-delay="200" className="sorter-row">
                    <Col sm={4} md={6} lg={'auto'}>
                        <Dropdown onSelect={handleLocationSorterSelect}>
                            <Dropdown.Toggle as={'a'} size={'lg'} id="dropdown-categories" className="category-toggle">
                                Location: {SelectLocationState}
                            </Dropdown.Toggle>
                            <Dropdown.Menu >
                                <Dropdown.Item className={SelectLocationState === 'All' ? 'active category-item' : 'category-item'} eventKey={"All"}>All</Dropdown.Item>
                                {LocationState.map((locations) => (
                                    <Dropdown.Item key={Helpers.generateKey(locations.title)} className={SelectLocationState === locations.title ? 'active category-item' : 'category-item'} eventKey={locations.title}>{locations.title}</Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>

                    <Col sm={4} md={6} lg={'auto'}>
                        <Dropdown onSelect={handleTeamSorterSelect} >
                            <Dropdown.Toggle as={'a'} size={'lg'} id="dropdown-categories" className="category-toggle">
                                Team: {SelectTeamState}
                            </Dropdown.Toggle>
                            <Dropdown.Menu >
                                <Dropdown.Item className={SelectTeamState === 'All' ? 'active category-item' : 'category-item'} eventKey={"All"}>All</Dropdown.Item>
                                {TeamState.map((teams) => (
                                    <Dropdown.Item key={Helpers.generateKey(teams.title)} className={SelectTeamState === teams.title ? 'active category-item' : 'category-item'} eventKey={teams.title}>{teams.title}</Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
                <Row data-aos="fade-up" data-aos-delay="200">
                    <Col>
                        <p className="job-count">{TotalJobCount} Job{TotalJobCount > 1 &&"s"}</p>
                    </Col>
                </Row>

                <Row className="align-items-center pt-5">
                    <Col xs={{ span: 12 }} className="career-list-wrapper">
                        {CareerState.length ? CareerState.map((career) => (
                            <Row className="align-items-center career-list-row" key={Helpers.generateKey(career.attributes.jobTitle)} data-aos="fade-up" data-aos-delay="200">
                                <Col xs={{ span: 12 }} md={{ span: 3 }} className="career-list-text" >
                                    <h3>{career.attributes.jobTitle}</h3>
                                </Col>
                                <Col md={3} className="location">
                                <h4>{career.attributes.location}</h4>

                                </Col>
                                <Col md={3} className="team"><h4>{career.attributes.department}</h4></Col>
                                <Col xs={12} md={3} className="link">
                                    <a href={career.attributes.listingUrl} target="_blank" rel="noreferrer">Apply Now</a>
                                </Col>
                            </Row>
                        )) : <Row className="align-items-center career-list-row title-row" data-aos="fade-up" data-aos-delay="200">
                            <Col xs={{ span: 12 }} md={{ span: 12 }} className="insight-list-text" >
                                <h2>No Positions Found, Please Adjust your Filters</h2>
                            </Col>
                        </Row>}
                    </Col>
                </Row>
            </div>
        </Container>
    );
};

export default CareerBlock;
