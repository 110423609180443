import { Row, Col, Container } from "react-bootstrap";
import React from "react";
import Markdown from 'markdown-to-jsx';


const ListColumnBlock = ({ data, classes }) => {


  return (
    <Container fluid className={classes + " hero-block"}>
      <Row>
        <Col data-aos={"fade-up"} xs={12} md={8} lg={5} className="header-wrapper">
          <h1>{data.header}</h1>
          <p>{data.body}</p>
        </Col>
      </Row>
      <Row className="list-row">
        {data.lists.map((list) => (
          <Col data-aos={"fade-up"} xs={12} md={8} lg={4} className="list-col">
          <div className="icon-container">
            <img className="icon mb-4" src={process.env.REACT_APP_STRAPI_API_URL + list?.icon?.data?.attributes?.url} height="44px"  alt={list?.icon?.data?.attributes?.alternativeText}/>
          </div>
            <div className="list-container">
              <h2>{list.header}</h2>
              <Markdown>{list.list}</Markdown>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default ListColumnBlock;
