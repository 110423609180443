import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
// import { slide as Menu } from 'react-burger-menu';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../assets/logo.svg';
import CookiePolicy from './CookiePolicy';


function Header() {
    const [button, setButton] = useState("")
    var location = window.location.pathname;
    useEffect(() => {
        if(location === "/careers" ) {
            setButton("active")
        } else {
            setButton("")
        }
    }, [location])
    return (
        <nav className="nav-bar">
        <Row>
            <Col xs={6}>
                <Link to="/">
                    <Logo className="white-logo" />
                </Link>
            </Col>
            <Col className="button-col" xs={6}>
                <div className={"button-container " + button }>
                    <Link to="/careers">
                    <Button className={"button"} id="career-button">Work with us</Button>
                </Link>
                </div>
            </Col>
            </Row>
            <CookiePolicy />
        </nav>
    )
}
export default Header
