import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/logo.svg";
import { ReactComponent as Linkedin } from "../assets/linkedin.svg";
import { ReactComponent as LogoRepeat } from "../assets/repeat-logo.svg"
import Markdown from "markdown-to-jsx";

function Footer({ footerData }) {
  return (
    <>
      <div data-aos={"fade-up"} className="step-wrapper">
        <LogoRepeat />

      </div>
      <div className="footer">
        <Row>
          <Col md={6} sm={12} className='footer-logo'>
            <div className="logo-container">
              <Link to={'/'}>
                <Logo />
              </Link>
            </div>
          </Col>
          <Col md={5} sm={10} className="information">
            <Markdown>{footerData.address}</Markdown>
            <a href={`mailto:${footerData.email}`}>{footerData.email}</a>
            <Row className="justify-content-end">

              {footerData.page_links.data.map((link) => (
                <Col md={"auto"} className="link-menu">
                  <Link to={'/' + link.attributes.slug} key={link.id}>{link.attributes.pageName}</Link>
                </Col>
              ))}
              <Col md={"auto"} className="link-menu">
                Copyright {new Date().getFullYear()}
              </Col>
            </Row>
          </Col>
          <Col md={1} sm={2} className="social">
            <a href={footerData.linkedinUrl} target="_blank" rel="noreferrer" className="icon"><Linkedin /></a>
          </Col>
        </Row>
        
      </div>
     
    </>
  );
}

export default Footer;
