import { Row, Col, Container } from "react-bootstrap";
import React from "react";


const CareersHeroBlock = ({ data, classes }) => {

const scrollToCareers = () => {
  document.querySelector('#jobs').scrollIntoView();
}

  return (
    <Container fluid className={classes + " hero-block"}>
      <Row>
        <Col data-aos={"fade-up"} xs={12} md={7} lg={7} className="header-col">
          <div>
            <h1 className="top-header">{data.topHeader}</h1>
            <h1 className="header">{data.header}</h1>
            <button className="button" onClick={scrollToCareers}>See all open positions</button>
          </div>
        </Col>
        <Col data-aos={"fade-up"} xs={12} md={5} lg={5} className="image-col">
            <div className="image-container">
              <img className="image" src={process.env.REACT_APP_STRAPI_API_URL + data?.image?.data?.attributes?.url} alt={process.env.REACT_APP_STRAPI_API_URL + data?.image?.data?.attributes?.alternativeText}></img>
            </div>
        </Col>
      </Row>
    </Container>
  );
};

export default CareersHeroBlock;
