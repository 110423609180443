import { Row, Col, Container } from "react-bootstrap";
import React from "react";


const QuoteBlock = ({ data, classes }) => {
  return (
    <Container fluid className={classes + " hero-block"}>
      <Row>
        <Col data-aos={"fade-up"} xs={12} md={5} lg={5} className="col quote-col">
          <div className="quote-container">
            <h2 className="top-header">{data.quote}</h2>
            <p>{data.name}</p>
            <p>{data.title}</p>
          </div>
        </Col>
        <Col data-aos={"fade-up"} xs={12} md={7} lg={7} className="col image-col">
          <div className="image-container">
            <img className="image" src={`${process.env.REACT_APP_STRAPI_API_URL}${data.image?.data?.attributes?.url}`} alt={data.image?.data?.attributes?.alternativeText}></img>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default QuoteBlock;
